import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'de',
    welcomeScreen: {
      title: i18n.t(`welcomeScreen.title`),
      blogPost: i18n.t(`welcomeScreen.blogPost`),
      welcomeScreen: true,
      photo: "old-salia",
    },
    contactCardsArray: [
      { chargeTitle: "Senior \n(x)", chargeName: "Robert Piosek \nS-Ss!", chargeContact: "+48 692 830 622", chargeMail: "x@salia-silesia.eu", chargePhoto: "robert-piosek-1" },
      { chargeTitle: "Consenior \n(xx)", chargeName: "Michael Wollny \nS-Ss!", chargeContact: "+48 886 808 043", chargeMail: "xx@salia-silesia.eu", chargePhoto: "michael-wollny" },
      { chargeTitle: "Fuxmajor \n(FM)", chargeName: "Thomas Kusiek \nS-Ss!", chargeContact: "+48 884 120 167", chargeMail: "fm@salia-silesia.eu", chargePhoto: "thomas-kusiek-fm" },
      { chargeTitle: "Scriptor \n(xxx)", chargeName: "Andreas Zygmuniak \nS-Ss!", chargeContact: "+48 662 292 707", chargeMail: "xxx@salia-silesia.eu", chargePhoto: "andreas-zygmuniak" },
      { chargeTitle: "Quaestor \n(xxxx)", chargeName: "Armin Piela \nS-Ss!", chargeContact: "+48 724 049 547", chargeMail: "xxxx@salia-silesia.eu", chargePhoto: "xd" },
      { chargeTitle: "Philister-Senior \n(Phil-x)", chargeName: "Peter Rybczyk", chargeContact: "+48 604 411 265", chargeMail: "phil.x@salia-silesia.de", chargePhoto: "peter-rybczyk" },
      { chargeTitle: "Philister-Consenior \n(Phil-xx)", chargeName: "Rafael Kowalczyk", chargeContact: "+48 602 631 681", chargeMail: "phil.xx@salia-silesia.de", chargePhoto: "xd" },
      { chargeTitle: "Philister-Scriptor \n(Phil-xxx)", chargeName: "Markus Heller", chargeContact: "+49 173 383 53 15", chargeMail: "phil.xxx@salia-silesia.de", chargePhoto: "xd" },
      { chargeTitle: "Philister-Quaestor \n(Phil-xxxx)", chargeName: "Franz Josef Ziegler", chargeContact: "+49 894 201 77 91", chargeMail: "phil.xxxx@salia-silesia.de", chargePhoto: "xd" },
      { chargeTitle: "Philister-Quaestor \nfür Oberschlesien \n(Phil-xxxx OS)", chargeName: "Peter Rybczyk", chargeContact: "+48 604 411 265", chargeMail: "phil.xxxx.os@salia-silesia.de", chargePhoto: "xd" },
      { chargeTitle: "Gesamtverziechnis \nBeauftrager \n(GVB)", chargeName: "Franz Josef Ziegler", chargeContact: "+49 894 201 77 91", chargeMail: "gvb@salia-silesia.de", chargePhoto: "xd" },
      { chargeTitle: "Seelsorger \nder Verbindung \n(GVB)", chargeName: "Arnold Nowak", chargeContact: "+48 604 107 358", chargeMail: "seelsorger@salia-silesia.de", chargePhoto: "arnold-nowak" }
    ],
    blogCardsArray: [
      { blogID: 6, blogTitle: i18n.t(`blogPostArray[6].blogTitle`), blogCoverPhoto: "aktivenfahrt-belgien", blogDate: "August 25, 2021" },
      { blogID: 7, blogTitle: i18n.t(`blogPostArray[7].blogTitle`), blogCoverPhoto: "honigverkostung", blogDate: "Juli 28, 2021" },
      { blogID: 8, blogTitle: i18n.t(`blogPostArray[8].blogTitle`), blogCoverPhoto: "semesterantrittskneipe-emilienhutte", blogDate: "März 16, 2021" },
      { blogID: 10, blogTitle: i18n.t(`blogPostArray[10].blogTitle`), blogCoverPhoto: "zimmer-frei", blogDate: "September 18, 2022" },
    ],
    blogPostArray: [
      {
        blogID: 0,
        blogTitle: i18n.t(`blogPostArray[0].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[0].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[0].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[0].blogHTML[0].chapterHTML`),
          }
        ],
        blogCoverPhoto: "old-salia",
        blogAdditionalPhotos: null,
      },
      {
        blogID: 1,
        blogTitle: i18n.t(`blogPostArray[1].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[1].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[1].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[1].blogHTML[0].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[1].blogHTML[1].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[1].blogHTML[1].chapterHTML`),
            chapterPhotos: [
              "cathedral",
              "technische-universitat",
              "amicitia",
              "schlesien-karte",
              "oppeln"
            ],
          }
        ],
        slideshowImages: [
          require("../assets/blogPhotos/chapterPhotos/cathedral.jpg"),
          require("../assets/blogPhotos/chapterPhotos/technische-universitat.jpg"),
          require("../assets/blogPhotos/chapterPhotos/amicitia.jpg"),
          require("../assets/blogPhotos/chapterPhotos/schlesien-karte.jpg"),
          require("../assets/blogPhotos/chapterPhotos/oppeln.jpg"),              
        ],
        blogCoverPhoto: "salia-wappen",
        blogAdditionalPhotos: null,
        aboutUs: true,
      },
      {
        blogID: 2,
        blogTitle: i18n.t(`blogPostArray[2].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[2].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[2].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[2].blogHTML[0].chapterHTML`),
          }
        ],
        blogCoverPhoto: "semesterprogramm",
        blogAdditionalPhotos: null,
        program: true,
      },
      {
        blogID: 3,
        blogTitle: i18n.t(`blogPostArray[3].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[3].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[0].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[1].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[1].chapterHTML`),
            chapterPhotos: [
              "grundung-1",
              "grundung-2",
              "grundung-3",
            ]
          },
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[2].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[2].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[3].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[3].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[4].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[4].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[5].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[5].chapterHTML`),
            chapterPhotos: [
              "merk-poster",
              "merk-leute",
            ]
          },
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[6].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[6].chapterHTML`),
            chapterPhotos: [
              "breslau-kommers"
            ]
          },
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[7].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[7].chapterHTML`),
          },
        ],
        slideshowImages: [
          require("../assets/blogPhotos/chapterPhotos/grundung-1.jpg"),
          require("../assets/blogPhotos/chapterPhotos/grundung-2.jpg"),
          require("../assets/blogPhotos/chapterPhotos/grundung-3.jpg"),
          require("../assets/blogPhotos/chapterPhotos/merk-poster.jpg"),
          require("../assets/blogPhotos/chapterPhotos/merk-leute.jpg"),
          require("../assets/blogPhotos/chapterPhotos/breslau-kommers.jpg"),            
        ],
        blogCoverPhoto: "salia",
        blogAdditionalPhotos: null,
        history: true,
      },
      {
        blogID: 4,
        blogTitle: i18n.t(`blogPostArray[4].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[4].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[4].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[4].blogHTML[0].chapterHTML`),
          }
        ],
        blogCoverPhoto: "haus-salia",
        blogAdditionalPhotos: null,
        contact: true,
      },
      // {
      //   blogID: 5,
      //   blogTitle: i18n.t(`blogPostArray[5].blogTitle`),
      //   blogPreview: i18n.t(`blogPostArray[5].blogPreview`),
      //   blogHTML: [
      //     {
      //       chapterTitle: i18n.t(`blogPostArray[5].blogHTML[0].chapterTitle`),
      //       chapterHTML: i18n.t(`blogPostArray[5].blogHTML[0].chapterHTML`),
      //     }
      //   ],
      //   blogCoverPhoto: "haus-salia",
      //   blogAdditionalPhotos: null,
      // },
      {
        blogID: 6,
        blogTitle: i18n.t(`blogPostArray[6].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[6].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[6].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[6].blogHTML[0].chapterHTML`),
          }
        ],
        blogCoverPhoto: "aktivenfahrt-belgien",
        blogAdditionalPhotos: null,
      },
      {
        blogID: 7,
        blogTitle: i18n.t(`blogPostArray[7].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[7].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[7].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[7].blogHTML[0].chapterHTML`),
          }
        ],
        blogCoverPhoto: "honigverkostung",
        blogAdditionalPhotos: null,
      },
      {
        blogID: 8,
        blogTitle: i18n.t(`blogPostArray[8].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[8].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[8].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[8].blogHTML[0].chapterHTML`),
          }
        ],
        blogCoverPhoto: "semesterantrittskneipe-emilienhutte",
        blogAdditionalPhotos: null,
      },
      {
        blogID: 9,
        blogTitle: i18n.t(`blogPostArray[9].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[9].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[9].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[9].blogHTML[0].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[9].blogHTML[1].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[9].blogHTML[1].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[9].blogHTML[2].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[9].blogHTML[2].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[9].blogHTML[3].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[9].blogHTML[3].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[9].blogHTML[4].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[9].blogHTML[4].chapterHTML`),
          },
        ],
        blogCoverPhoto: "salia-wappen",
        blogAdditionalPhotos: null,
      },
      {
        blogID: 10,
        blogTitle: i18n.t(`blogPostArray[10].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[10].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[10].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[10].blogHTML[0].chapterHTML`),
            chapterPhotos: [
              "zimmer-frei-1",
              "zimmer-frei-2",
              "zimmer-frei-3",
              "zimmer-frei-4",
              "zimmer-frei-5",
              "zimmer-frei-6",
              "zimmer-frei-7",
              "zimmer-frei-8",
            ]
          },
        ],
        slideshowImages: [
          require("../assets/blogPhotos/chapterPhotos/zimmer-frei-1.jpg"),
          require("../assets/blogPhotos/chapterPhotos/zimmer-frei-2.jpg"),
          require("../assets/blogPhotos/chapterPhotos/zimmer-frei-3.jpg"),
          require("../assets/blogPhotos/chapterPhotos/zimmer-frei-4.jpg"),
          require("../assets/blogPhotos/chapterPhotos/zimmer-frei-5.jpg"),
          require("../assets/blogPhotos/chapterPhotos/zimmer-frei-6.jpg"),
          require("../assets/blogPhotos/chapterPhotos/zimmer-frei-7.jpg"),
          require("../assets/blogPhotos/chapterPhotos/zimmer-frei-8.jpg"),
        ],
        blogCoverPhoto: "zimmer-frei",
        blogAdditionalPhotos: null,
        zimmerFrei: true,
      },     
    ],
  },
  getters: {
    priorityFeed: (state) => (blogID) => {
      return state.blogPostArray.find(blog => blog.blogID === blogID);
    },
    blogPostFeed(state) {
      return state.blogPostArray.slice(1, 5);
    },
    blogPostCards(state) {
      return state.blogCardsArray.slice(0, 4);
    },
    contactCardsAktivitas(state) {
      return state.contactCardsArray.slice(0, 5);
    },
    contactCardsPhilister(state) {
      return state.contactCardsArray.slice(5, 13);
    },
/*     translatedBlogCardsArray: (state, getters) => {
      const locale = i18n.locale;
      return state.items.map(item => ({
        title: i18n.t(item.title)
      }))
    } */

    getLanguage: state => state.language
  },
  mutations: {
    changeLanguage (state, data) {
      this.state.language = data
    }
  },
  actions: {
    changeLanguage: ({ commit }, data) => commit('changeLanguage', data)
  },
  modules: {
  }
})
